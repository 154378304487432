import styled, { CSSObject } from '@emotion/styled';
import { ifNotProp, ifProp, switchProp } from 'styled-tools';
import { InputFieldProps } from './InputField';

type StyledInputProps = {
    withLabel: boolean;
    isActive: boolean;
    withPrepend: boolean;
    asFiles: boolean;
};

type StyledLabelProps = {
    hasFocus: boolean;
    isActive: boolean;
    isValid: boolean;
};

type StyledInputFieldWrapperProps = {
    isValid: boolean;
};

export const StyledInput = styled.input<StyledInputProps>(
    ({ theme }) =>
        ({
            ...theme.fontVariants.body,
            fontSize: '16px', // to avoid zoom on IOS
            lineHeight: theme.lineHeights.single,
            backgroundColor: 'transparent',
            outline: 0,
            border: 'none',
            width: '100%',
            boxSizing: 'border-box',
            display: 'block',
            padding: `${theme.spaces[3]} ${theme.spaces[6]}`,
            textAlign: theme.direction === 'rtl' ? 'right' : 'left',
            '&::placeholder': {
                transition: '0.1s color',
                color: theme.colors.grey40,
            },
            '&:read-only': {
                opacity: 0.7,
            },
            '&[type="file"]': {
                opacity: 0.7,
                color: theme.colors.grey80,
                cursor: 'pointer',
                '&::-webkit-file-upload-button': {
                    visibility: 'hidden',
                    width: 0,
                    padding: 0,
                    margin: 0,
                    marginLeft: '-4px',
                },
            },
        }) as CSSObject,
    ifProp(
        { asFiles: false },
        {
            '&[type="file"]': {
                opacity: 0,
            },
        },
    ),
    ifProp('withLabel', ({ theme }) => ({
        padding: `2.5rem ${theme.spaces[6]} 0.8rem ${theme.spaces[6]}`,
    })),
    ifProp(
        { withLabel: true, isActive: false },
        {
            [`&::placeholder`]: {
                color: 'transparent',
            },
        },
    ),
    ifProp(
        { withPrepend: true },
        {
            paddingLeft: '0.1rem',
            marginRight: '-0.9rem',
        },
    ),
);

export const StyledInputField = styled.div<{ colorTheme: InputFieldProps['theme'] }>(
    ({ theme }) => ({
        borderRadius: theme.borderRadius.input,
        overflow: 'hidden',
        lineHeight: theme.lineHeights.single,
        position: 'relative',
        display: 'flex',
    }),
    switchProp('colorTheme', {
        light: ({ theme }) => ({
            backgroundColor: theme.colorTraits.inputBackground.light,
        }),
        grey: ({ theme }) => ({
            backgroundColor: theme.colorTraits.inputBackground.grey,
        }),
    }),
);

export const StyledLabel = styled.label<StyledLabelProps>(
    ({ theme }) =>
        ({
            ...theme.fontVariants.body,
            position: 'absolute',
            top: '50%',
            left: theme.spaces[6],
            transition: `${theme.animations.timingMedium} transform ${theme.animations.easeOutCubic}, ${theme.animations.timingMedium} width ${theme.animations.easeOutCubic}`,
            color: theme.colors.grey60,
            transform: 'translateY(-50%)',
            width: `calc(100% - ${theme.spaces[6]} - ${theme.spaces[6]})`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transformOrigin: theme.direction === 'rtl' ? 'right top' : 'left top',
            pointerEvents: 'none',
            zIndex: 2,
        }) as CSSObject,
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp(
        { isActive: true },
        {
            transform: 'scale(0.75) translateY(-2.9rem)',
            // width: '110%',
        },
    ),
);

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>({
    position: 'relative',
});

export const StyledAdditionalContent = styled.span<{
    clickable: boolean;
    isPrepend?: boolean;
    withLabel?: boolean;
}>(({ theme, clickable, isPrepend, withLabel }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: !isPrepend ? theme.spaces[6] : 0,
    paddingLeft: isPrepend ? theme.spaces[6] : 0,
    paddingTop: isPrepend && withLabel ? theme.spaces[6] : 0,
    paddingBottom: isPrepend && withLabel ? '0.7rem' : 0,
    fontSize: theme.fontSizes.xs,
    pointerEvents: clickable ? undefined : 'none',

    svg: {
        overflow: 'visible',
    },
}));
