import React, { Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useInputField } from '../../hooks/useInputField';
import {
    StyledInputField,
    StyledInputFieldWrapper,
    StyledLabel,
    StyledInput,
    StyledAdditionalContent,
} from './styled';
import { StyledInvalidMessage } from '../InvalidMessage';
import { StyledHelpText } from '../HelpText';
import { InputColorTheme } from '../../types';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type InputFieldProps = Omit<InputProps, 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * This component is uncontrolled - you will need to controll the value.
     */
    value?: string;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;

    /**
     * Append a string or a component to the input field
     */
    append?: React.ReactNode;

    /**
     * prePend a string or a component to the input field
     */
    prepend?: React.ReactNode;

    /**
     * Should the input validate on every change to value
     */
    validateOnValueChange?: boolean;

    /**
     * Controls wether appended content should be clickable
     */
    clickableAppend?: boolean;

    /**
     * isFocused
     */
    isFocused?: boolean;

    /**
     * If user added files to a fileInput
     */
    asFiles?: boolean;

    theme?: InputColorTheme;
};

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        {
            theme = 'grey',
            asFiles = false,
            clickableAppend = false,
            label,
            helpText,
            invalidMessage,
            id,
            children,
            isActive,
            isInvalid,
            append,
            prepend,
            validateOnValueChange,
            isFocused,
            ...rest
        },
        ref,
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const [hasFocus, setHasFocus] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const inputFieldRef = useRef<HTMLDivElement>(null);

        const {
            fieldId,
            labelId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const { value, defaultValue } = rest;

        const hasValue = useMemo(() => {
            return !value ? !!defaultValue : !!value;
        }, [defaultValue, value]);

        const isInputActive = isActive || hasFocus || hasValue;
        const isValid = !showInvalidMessage && isValidityValid;

        const isLabelActive = useMemo(() => {
            return isActive || hasFocus || hasValue || !!(validateOnValueChange && !isValid);
        }, [hasValue, validateOnValueChange, isValid, isActive, hasFocus]);

        const onFocusHandler = () => setHasFocus(true);
        const onBlurHandler = () => {
            setHasFocus(false);
            setIsValidityValid(!!inputRef.current?.validity.valid);
        };

        useEffect(() => {
            if (validateOnValueChange) {
                onBlurHandler();
            }
        }, [value]);

        useEffect(() => {
            if (isFocused && inputRef.current) {
                setTimeout(() => {
                    inputRef?.current?.focus();
                }, 100);
            }
        }, [isFocused, inputRef]);

        const onInputFieldClick = (event: React.MouseEvent) => {
            if (inputRef.current !== event.target) {
                inputRef.current?.focus();
                inputRef.current?.click();
                event?.preventDefault();
                event?.stopPropagation();
            }
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        return (
            <Fragment>
                <StyledInputFieldWrapper
                    key={fieldId}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    isValid={isValid}
                >
                    <StyledInputField
                        onMouseDown={onInputFieldClick}
                        ref={inputFieldRef}
                        colorTheme={theme}
                    >
                        {label && (
                            <StyledLabel
                                id={labelId}
                                htmlFor={fieldId}
                                title={label}
                                isActive={
                                    rest.type === 'file' ? (asFiles ? true : false) : isLabelActive
                                }
                                isValid={isValid}
                                hasFocus={hasFocus}
                            >
                                {/* required fields always have an invalidMessage with an empty string */}
                                {`${label}${invalidMessage !== undefined ? ' *' : ''}`}
                            </StyledLabel>
                        )}
                        {prepend ? (
                            <StyledAdditionalContent
                                aria-hidden="true"
                                children={prepend}
                                isPrepend
                                withLabel={!!label}
                                clickable={clickableAppend}
                            />
                        ) : null}
                        <StyledInput
                            withLabel={!!label}
                            isActive={isInputActive}
                            withPrepend={!!prepend}
                            id={fieldId}
                            aria-describedby={describedById}
                            ref={inputRef}
                            asFiles={asFiles}
                            {...rest}
                        />

                        {append ? (
                            <StyledAdditionalContent
                                children={append}
                                clickable={clickableAppend}
                            />
                        ) : null}
                    </StyledInputField>
                    {children}
                </StyledInputFieldWrapper>
                {showInvalidMessage ? (
                    <StyledInvalidMessage id={invalidMessageId} children={invalidMessage} />
                ) : null}
                {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
            </Fragment>
        );
    },
);

InputField.displayName = 'InputField';
